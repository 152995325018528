import {
  AppAuthError,
  AuthorizationRequest,
  AuthorizationRequestResponse,
  AuthorizationServiceConfiguration,
  BaseTokenRequestHandler,
  BasicQueryStringUtils,
  DefaultCrypto,
  FetchRequestor,
  GRANT_TYPE_AUTHORIZATION_CODE,
  GRANT_TYPE_REFRESH_TOKEN,
  LocalStorageBackend,
  LocationLike,
  RedirectRequestHandler,
  StringMap,
  TokenRequest,
  TokenRequestHandler,
  TokenResponse,
} from "@openid/appauth";

import config from "./config";
import { IdentityProvider, OAuth2Configuration } from "./global";

class NoHashQueryStringUtils extends BasicQueryStringUtils {
  parse(input: LocationLike) {
    return super.parse(input, false);
  }
}

/**
 * Subclass of RedirectRequestHandler that allows exposing the result of
 * completeAuthorizationRequest (which is a protected method by default).
 */
class CustomRedirectRequestHandler extends RedirectRequestHandler {
  completeAuthorizationRequest(): Promise<AuthorizationRequestResponse | null> {
    return super.completeAuthorizationRequest();
  }
}

class AppAuth {
  private env: OAuth2Configuration;
  private configuration: AuthorizationServiceConfiguration;
  private authorizationHandler: CustomRedirectRequestHandler;
  private tokenHandler: TokenRequestHandler;

  constructor(env: OAuth2Configuration) {
    this.env = env;

    this.configuration = new AuthorizationServiceConfiguration({
      authorization_endpoint: env.authEndpoint,
      token_endpoint: env.tokenEndpoint,
      revocation_endpoint: "",
    });

    this.authorizationHandler = new CustomRedirectRequestHandler(
      new LocalStorageBackend(),
      new NoHashQueryStringUtils(),
      window.location,
      new DefaultCrypto()
    );
    this.tokenHandler = new BaseTokenRequestHandler(
      new FetchRequestor(),
      new NoHashQueryStringUtils()
    );
  }

  async refresh(refreshToken: string): Promise<TokenResponse> {
    const request = new TokenRequest({
      client_id: this.env.clientId,
      redirect_uri: this.env.redirectUri,
      grant_type: GRANT_TYPE_REFRESH_TOKEN,
      refresh_token: refreshToken,
    });

    try {
      return await this.tokenHandler.performTokenRequest(this.configuration, request);
    } catch (e) {
      if (e instanceof AppAuthError && e.message == "400")
        window.dispatchEvent(new CustomEvent("phellow:event:logout"));

      throw e;
    }
  }

  authorizationRequest() {
    const request = new AuthorizationRequest(
      {
        client_id: this.env.clientId,
        redirect_uri: this.env.redirectUri,
        scope: this.env.scopes.join(" "),
        response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
        extras: this.env.prompt ? { prompt: this.env.prompt } : {},
      },
      new DefaultCrypto(),
      this.env.pkce
    );

    return this.authorizationHandler.performAuthorizationRequest(this.configuration, request);
  }

  async completeIfPossible(): Promise<TokenResponse | null> {
    const result = await this.authorizationHandler.completeAuthorizationRequest();
    if (!result) {
      return null;
    }
    if (result.error) {
      throw result.error;
    }
    return this.codeExchange(result.response!!.code, result.request?.internal?.code_verifier);
  }

  private async codeExchange(code: string, codeVerifier?: string): Promise<TokenResponse> {
    const extras: StringMap = {};

    if (codeVerifier) {
      extras.code_verifier = codeVerifier;
    }

    const request = new TokenRequest({
      client_id: this.env.clientId,
      redirect_uri: this.env.redirectUri,
      grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
      code,
      extras,
    });

    return this.tokenHandler.performTokenRequest(this.configuration, request);
  }
}

export default function appAuthFor(provider: IdentityProvider): AppAuth {
  return new AppAuth(config.openid[provider]!!);
}
